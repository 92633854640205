import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import NFoiOQlogo from '../assets/nfoioq-logo.svg';

const useStyles = makeStyles((theme) => ({
  '@keyframes rainbowAnimation' : {
    '0%,100%': {
        backgroundPosition: '0 0',
    },
    '50%': {
        backgroundPosition: '100% 0',
    }
  },
  appbar: {
    background: 'linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff)',
    animation: '$rainbowAnimation 6s ease-in-out infinite',
    backgroundSize: '400% 100%',
  },
}));

export default function CustomAppbar() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <Tooltip title='Ñ Foi o Q?'>
          <a href="https://nfoioq.com"><img src={NFoiOQlogo} alt='Ñ Foi o Q?' style={{height:'60px'}}/></a>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}