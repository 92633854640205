import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Modal(props) {
  const classes = useStyles();

  return (
    <MaterialUIModal
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box
          display='flex'
          flexDirection='column'
          onClick={props.handleClose}
        >
          {props.children}
        </Box>
      </Fade>
    </MaterialUIModal>
  );
};