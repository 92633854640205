import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export default function NameCard (props) {
  return (
    <Card>
      <Box display='flex' height={50} alignItems="center" margin={2}>
        <Box width={'70%'} textOverflow="ellipsis">
          {props.name}
        </Box>
        <Box width={'30%'} display='flex' justifyContent="flex-end">
          <IconButton onClick={props.onClickDelete} style={{color: '#ffffff'}}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  )
};