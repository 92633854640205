import React, {Fragment} from "react";
import DropZone from "react-dropzone";
import { makeStyles } from '@material-ui/core/styles';
// import Modal from './modal.js';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    height: '100vh',
    width: '100%',
  },
}));

// function DragActiveModal (props) {
//   console.log({isDragActive: props.open})
//   return (
//     <Modal open={props.open}>
//       KKKKKKKKKKKKKKKKKKK
//     </Modal>
//   )
// };

export default function DropzoneFullscreen({
  onDrop,
  onChange,
  children,
}) {

  const classes = useStyles();
  return (
    <DropZone
      accept=".csv, text/csv, .txt"
      onDrop={onDrop}
      onChange={onChange}
    >
      {({getRootProps, isDragActive}) => (
        <Fragment>
          <div
            className={classes.dropzone}
            {...getRootProps()}
          >
              {children}
          </div>
          {/* <DragActiveModal open={isDragActive}/> */}
        </Fragment>
      )}
    </DropZone>
  );
};