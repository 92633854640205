import {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import NameCard from './container/nameCard.js';
import DiceButton from './container/diceButton.js';
import ModalSeFudeu from './container/modalSeFudeu.js';
import TextField from './container/textField.js';
import Appbar from './container/appbar.js';
import DropzoneFullscreen from './container/dropzoneFullscreen.js';
import queryString from 'query-string';


const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  textfieldBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  deleteIconBox: {
    marginLeft: theme.spacing(1),
  },
  deleteIcon: {
    fontSize: "1.3em"
  },
  box: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  buttonBox: {
    position: 'fixed',
    width: '100%',
    top: 'calc(100% - 150px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center'
  }
}));

function App() {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [nameList, setNameList] = useState([]);
  const [theChosenOne, setTheChosenOne] = useState('');
  const [copyToClipboardString, setCopyToClipBoardString] = useState('');

  const handleNameChange = (event) => {
    let newName = event.target.value;
    if (newName && newName !== '\n') {
      setName(event.target.value);
    }
    else {
      setName('');
    };
  };

  const inputAdd = (inputName) => {
    if (inputName) {
      let re=/\r\n|\n\r|\n|\r|,|;|\|/g;
      let _nameList = [...nameList];
      let newNameList = inputName.trim().split(re).filter(Boolean);
      _nameList = _nameList.concat(newNameList);
      _nameList.sort();
      setNameList(_nameList);
      setName('');
      return _nameList;
    };
  };

  const onKeyDown = (event) => {
    if(event.keyCode === 13){
      inputAdd(name);
    };
  };

  const onDeleteName = (index) => {
    setNameList(nameList => nameList.slice(0, index).concat(nameList.slice(index + 1)));
  };

  const buildURL = (nameList, theChosenOne=null) => {
    let url = window.location.href.split('?')[0];

    let query = {
      options: nameList,
    };
    if (theChosenOne) {
      query.theChosenOne = theChosenOne;
    };

    return `${url}?${new URLSearchParams(query).toString()}`;
  };

  const onDiceClick = () => {
    let length = nameList.length;
    if (length > 0) {
      let index = Math.floor(Math.random() * length);
      let _theChosenOne = nameList[index];
      setTheChosenOne(_theChosenOne);
      setCopyToClipBoardString(buildURL(nameList, _theChosenOne));
    };
  };

  const clearAll = () => {
    setNameList([]);
    setName('');
  };

  const handleModalClose = () => {
    setTheChosenOne('');
  };

  const onDropFile = (acceptedFiles, rejectedFiles) => {
    console.log('onDropFile', {acceptedFiles, rejectedFiles});
    acceptedFiles.forEach((file) => {
      let reader = new FileReader();
      reader.onload = function(e) {
        var contents = e.target.result;
        inputAdd(contents);
      };
      reader.readAsText(file);
    })
  };

  const onChangeFile = (e) => {
    console.log('onChangeFile', e);
  };

  useEffect(() => {
    let query = queryString.parse(window.location.search);
    let options = query?.options;
    let _theChosenOne = query?.theChosenOne;
    console.log({query});
    if (options) {
      let _nameList = inputAdd(options);
      console.log({
        options: _nameList,
        theChosenOne: _theChosenOne,
      });
      if (_theChosenOne && Array.isArray(_nameList) && _nameList.includes(_theChosenOne)) {
        setTheChosenOne(_theChosenOne);
        setCopyToClipBoardString(buildURL(_nameList, _theChosenOne));
      };
    };
  // eslint-disable-next-line
  }, []);

  return (
    <DropzoneFullscreen
      onDrop={onDropFile}
      onChange={onChangeFile}
    >
      <Appbar />
      <Container maxWidth='lg'>
        <Box className={classes.main}>
          <Box className={classes.textfieldBox}>
            <Box flexGrow={1}>
              <TextField
                label="Nome"
                multiline
                maxRows={3}
                height={100}
                value={name}
                onChange={handleNameChange}
                onKeyDown={onKeyDown}
                onIconClick={() => inputAdd(name)}
              />
            </Box>
            <Box className={classes.deleteIconBox}>
              <IconButton onClick={clearAll}>
                <DeleteForeverIcon className={classes.deleteIcon}/>
              </IconButton>
            </Box>
          </Box>

          <Box className={classes.box}>
            <Grid container spacing={1}>
              {nameList.map((name, index) => (
                <Grid
                  key={`name-${index}`}
                  item
                  xs={6}
                  md={4}
                  lg={3}
                >
                  <NameCard
                    name={name}
                    onClickDelete={() => onDeleteName(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className={classes.buttonBox}>
            <DiceButton onClick={onDiceClick} disabled={nameList.length < 2}/>
          </Box>
        </Box>
      </Container>
      <ModalSeFudeu
        open={Boolean(theChosenOne)}
        name={theChosenOne}
        handleClose={handleModalClose}
        copyToClipboardString={copyToClipboardString}
      />
    </DropzoneFullscreen>
  );
}

export default App;
