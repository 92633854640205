import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 24,
    background: theme.palette.primary.main,
    color: '#ffffff',
    opacity: 1,
    boxShadow: theme.shadows[5],
    "&:hover" : {
      background: theme.palette.primary.main,
      opacity: 1 - theme.palette.action.hoverOpacity,
      boxShadow: theme.shadows[10],
    },
    transition: theme.transitions.create(["background", "color", "opacity"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  icon: {
    fontSize: "2.5em"
  }
}));

export default function DiceButton(props) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.button}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <FileCopy className={classes.icon}/>
    </IconButton>
  );
};
