import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';

export default function TextFieldCustom(props) {

  return (
    <TextField
      label="Nome"
      value={props.value}
      variant='outlined'
      autoFocus
      fullWidth
      maxRows={props.maxRows}
      multiline={props.multiline}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      InputProps={{
        endAdornment: (
          <IconButton onClick={props.onIconClick}>
            <AddBoxIcon />
          </IconButton>
        ),
        style: {
          height: props.height,
        },
      }}
    />
  );
};