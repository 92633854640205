import React from 'react';
import Modal from './modal.js';
import Box from '@material-ui/core/Box';
import CopyButton from './copyButton';

export default function DiceButton(props) {
  return (
    <Modal open={props.open} handleClose={props.handleClose}>
      <Box textAlign="center" fontWeight={500} fontSize={"max(6vw, 30px)"}>
        {`${props.name}`}
      </Box>
      <Box textAlign="center" fontWeight={500} fontSize={"max(7vw, 40px)"}
        style={{
          backgroundImage: 'linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red)',
          'WebkitBackgroundClip': 'text',
          color: 'transparent',
        }}
      >
        Se Fudeu!
      </Box>
      <Box style={{position: 'absolute', top: "80%", left: '80%'}}>
        <CopyButton
          onClick={() => {navigator.clipboard.writeText(props.copyToClipboardString)}}
        />
      </Box>
    </Modal>
  );
};
